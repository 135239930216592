var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('screen',{staticClass:"congrats-view",attrs:{"size":"small"}},[_c('section',{staticClass:"user-edit-view"},[_c('h3',[_vm._v("Verify Your Information")]),_c('p',[_vm._v(" To meet all applicable laws, please make sure your information is up to date. ")]),(_vm.error)?_c('div',{staticClass:"roundup-alert roundup-alert-error"},[_vm._v(" "+_vm._s(_vm.error)+" ")]):_vm._e(),_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('ui-label',[_c('ui-input',{attrs:{"placeholder":"First Name"},model:{value:(_vm.user.first_name),callback:function ($$v) {_vm.$set(_vm.user, "first_name", $$v)},expression:"user.first_name"}})],1),_c('ui-label',[_c('ui-input',{attrs:{"placeholder":"Last Name"},model:{value:(_vm.user.last_name),callback:function ($$v) {_vm.$set(_vm.user, "last_name", $$v)},expression:"user.last_name"}})],1),_c('ui-label',[_c('ui-input',{attrs:{"placeholder":"Email"},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}})],1),_c('ui-label',[_c('ui-input',{attrs:{"type":"number","placeholder":"Cell/ Direct Phone"},model:{value:(_vm.user.phone),callback:function ($$v) {_vm.$set(_vm.user, "phone", $$v)},expression:"user.phone"}})],1),_c('ui-label',[_c('ui-input',{attrs:{"placeholder":"Title"},model:{value:(_vm.user.title),callback:function ($$v) {_vm.$set(_vm.user, "title", $$v)},expression:"user.title"}})],1),_c('ui-label',{style:({
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center'
				})},[_c('ui-toggle',{style:({ flex: 1 }),attrs:{"checked":_vm.user.request_contact},on:{"input":function($event){return _vm.checked('request_contact')}}}),_c('p',{style:({ flex: 4 })},[_vm._v(" I want to be contacted by an growth specialist. ")])],1),_c('ui-label',{style:({
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center'
				})},[_c('ui-toggle',{style:({ flex: 1 }),attrs:{"checked":_vm.user.email_subscribe},on:{"input":function($event){return _vm.checked('email_subscribe')}}}),_c('p',{style:({ flex: 4 })},[_vm._v(" I would like to receive updates and news about RoundUp App. ")])],1),_c('ui-button',{attrs:{"type":"success","native-type":"submit","loading":_vm.isSaving}},[_vm._v("Save")])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }